import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import FooterSection from "../sections/Footer";
import Top from "../sections/Top";
import PageTitle from "../components/PageTitle";

import Strat from "../images/logos/logo-stratandgo.svg";
import Bovoba from "../images/logos/logo-bovoba.svg";
import Ramaroo from "../images/logos/logo-ramaroo.svg";
import Brightening from "../images/logos/logo-brighteninghero.svg";
// import Fitness from "../images/logos/logo-fitnessapp.svg";
import Dark from "../images/logos/logo-darkspectrumtattoo.svg";

const StyledContent = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  width: 100%;
  max-width: var(--content-size);
  margin: 0 auto 30px auto;
  padding: 20px;

  .card {
    list-style-type: none;
    min-height: 300px;
    background: var(--dark);
    color: var(--light);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 40px 20px;
    text-decoration: none;
    transition: 0.2s ease-in;
    position: relative;
    @media (max-width: 600px) {
      min-height: 200px;
      flex-direction: column;
      padding-top: 0;
    }
    &:after {
      content: "View project";
      position: absolute;
      right: 10px;
      bottom: -10px;
      opacity: 0;
      transition: 0.2s ease-in;
      color: var(--c-two);
      @media (max-width: 600px) {
        opacity: 1;
        bottom: 10px;
      }
    }
    &:hover {
      opacity: 0.9;
      &:after {
        opacity: 1;
        bottom: 10px;
      }
      @media (max-width: 600px) {
        opacity: unset;
      }
    }
    .logo {
      width: 30%;
      @media (max-width: 600px) {
        width: 100%;
      }
      img {
        max-width: 150px;
        max-height: 150px;
        user-drag: none;
        user-select: none;
        -webkit-user-drag: none;
      }
    }
    .text {
      width: 67%;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      @media (max-width: 600px) {
        width: 100%;
      }
      .title {
        font-size: 26px;
        margin-bottom: 14px;
        color: var(--c-two);
        text-align: left;
        font-weight: 300;
      }
      .subtitle {
        font-size: 20px;
        text-align: left;
        font-weight: 300;
      }
    }
  }
`;

const Description = styled.section`
  width: 70%;
  max-width: var(--content-size);
  margin: 80px auto 60px auto;
  padding: 20px;
  @media (max-width: 1150px) {
    width: 100%;
  }
  h2 {
    font-weight: 400;
    position: relative;
    line-height: 30px;
    font-size: 24px;
    &:first-of-type {
      margin-bottom: 30px;
    }
    @media (max-width: 600px) {
      text-align: left;
    }
    sub {
      font-size: 40px;
      font-style: italic;
      opacity: 0.3;
    }
  }
`;

const Work = () => {
  return (
    <div className="work-page">
      <PageTitle title="Work | @designwithella.com" />
      <Top title="Recent projects" />
      <Description>
        <h2>
          <sub>"</sub> Discover my UX/UI design portfolio, a showcase of my
          multidisciplinary approach to digital design.
        </h2>
        <h2>
          As you explore my projects, witness my ability to combine user
          research, information architecture, interaction design, and visual
          aesthetics to create immersive and user-friendly experiences across
          various platforms.
          <sub>"</sub>
        </h2>
      </Description>
      <StyledContent>
        <NavLink to="/project-strat-and-go" className="card card--1">
          <div className="logo">
            <img src={Strat} alt="project-strat-and-go" />
          </div>

          <div className="text">
            <p className="title">STRATandGO Software Application</p>
            <p className="subtitle">
              Strategy and Corporate Performance Management System
            </p>
          </div>
        </NavLink>
        <NavLink
          to="/project-ramaroo-ugc-video-player"
          className="card card--2"
        >
          <div className="logo">
            <img src={Ramaroo} alt="project-ramaroo-ugc-video-player" />
          </div>
          <div className="text">
            <p className="title">Ramaroo UGC Video Player</p>
            <p className="subtitle">
              Video shopping platform designed for brands to uplift conversions
              and increase retention
            </p>
          </div>
        </NavLink>
        <NavLink to="/project-bovoba-dashboard" className="card card--3">
          <div className="logo">
            <img src={Bovoba} alt="project-bovoba-dashboard" />
          </div>
          <div className="text">
            <p className="title">Bovoba SaaS Application</p>
            <p className="subtitle">
              E&#8209;Commerce integration platform to notify customers when
              fulfilment and shipping delays occur
            </p>
          </div>
        </NavLink>
        <NavLink to="/project-brightening-hero" className="card card--4">
          <div className="logo">
            <img src={Brightening} alt="project-brightening-hero" />
          </div>
          <div className="text">
            <p className="title">Brightening Hero E&#8209;Commerce</p>
            <p className="subtitle">
              Bespoke e&#8209;commerce responsive website
            </p>
          </div>
        </NavLink>
        <NavLink to="/project-dark-spectrum-tattoo" className="card card--5">
          <div className="logo">
            <img src={Dark} alt="project-dark-spectrum-tattoo" />
          </div>
          <div className="text">
            <p className="title">Dark Spectrum Tattoo</p>
            <p className="subtitle">Tattoo Shop website design concept</p>
          </div>
        </NavLink>
        {/* <NavLink to="/project-fitness-app" className="card card--6">
          <div className="logo">
            <img src={Fitness} alt="project-fitness-app" />
          </div>
          <div className="text">
            <p className="title">First Run</p>
            <p className="subtitle">Mobile Fitness App for new runners</p>
          </div>
        </NavLink> */}
      </StyledContent>
      <FooterSection />
    </div>
  );
};

export default Work;
